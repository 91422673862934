// Set default icon color
let fillColor;
export const ColorSwitch = (propColor) => {
  switch (propColor) {
    case "white":
      fillColor = "#FFF";
      break;
    case "coral":
      fillColor = "#FF7F66";
      break;
    default:
      fillColor = "#243D42";
      break;
  }
  return fillColor;
};

// Icons
const AppStore = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="40"
      fill="none"
      viewBox="0 0 120 40"
    >
      <g fill={fillColor}>
        <path d="M23.759 7.84687C23.8597 9.16154 23.4439 10.4637 22.6 11.4768C22.1959 11.9794 21.683 12.3837 21.1 12.6594C20.517 12.9351 19.879 13.0748 19.2341 13.0682C19.193 12.436 19.2769 11.8019 19.4809 11.2021C19.685 10.6023 20.0053 10.0486 20.4235 9.57269C21.2788 8.59848 22.4697 7.98228 23.759 7.84687Z" />
        <path d="M26.1112 17.4154C25.6828 18.1703 25.4522 19.0214 25.441 19.8893C25.4421 20.8656 25.7305 21.8201 26.2703 22.6336C26.8102 23.4471 27.5775 24.0837 28.4768 24.464C28.1232 25.6125 27.5903 26.6979 26.8978 27.6799C25.9677 29.0712 24.9925 30.4304 23.4448 30.4555C22.7086 30.4725 22.2118 30.2608 21.694 30.0403C21.1539 29.8102 20.5911 29.5705 19.7105 29.5705C18.7765 29.5705 18.1885 29.8179 17.6214 30.0566C17.1314 30.2628 16.6569 30.4625 15.9883 30.4902C14.5143 30.5448 13.3878 29.0053 12.4239 27.6271C10.4974 24.8125 8.99728 19.6951 11.0084 16.2131C11.4727 15.3788 12.1451 14.679 12.9603 14.1819C13.7755 13.6847 14.7055 13.4072 15.6598 13.3764C16.4958 13.3592 17.298 13.6814 18.0013 13.9639C18.5391 14.1799 19.0191 14.3728 19.4122 14.3728C19.7578 14.3728 20.2244 14.1876 20.7682 13.9717C21.6249 13.6317 22.6731 13.2157 23.7412 13.3278C24.5598 13.3535 25.3612 13.5696 26.0817 13.9591C26.8021 14.3486 27.4219 14.9007 27.8916 15.5716C27.1522 16.0262 26.5397 16.6605 26.1112 17.4154Z" />
        <path d="M75.1224 29.0761C73.4877 29.0761 72.2914 28.2675 72.1537 27.0361L70.2513 27.036C70.3714 29.2821 72.2308 30.703 74.9847 30.703C77.9281 30.703 79.7777 29.2479 79.7777 26.9247C79.7777 25.1083 78.7201 24.0848 76.1722 23.4823L74.8041 23.1463C73.2035 22.7509 72.5492 22.2343 72.5492 21.3563C72.5492 20.2548 73.5736 19.4979 75.0707 19.4979C76.5677 19.4979 77.5922 20.246 77.7045 21.4852H79.5805C79.5199 19.3427 77.7211 17.871 75.0961 17.871C72.4457 17.871 70.5785 19.3427 70.5785 21.4852C70.5785 23.2157 71.6371 24.2821 73.9174 24.8329L75.5267 25.2206C77.1361 25.6161 77.8158 26.1933 77.8158 27.1571C77.8158 28.2675 76.6889 29.0761 75.1224 29.0761Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7461 27.1396H38.0127L36.876 30.496H34.8711L39.3545 18.078H41.4375L45.9209 30.496H43.8818L42.7461 27.1396ZM38.5029 25.5908H42.2549L40.4053 20.1435H40.3535L38.5029 25.5908Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M55.6034 25.9697C55.6034 28.7831 54.0976 30.5908 51.8251 30.5908C51.2495 30.6209 50.677 30.4883 50.1732 30.2081C49.6694 29.928 49.2547 29.5116 48.9765 29.0068H48.9335V33.4911H47.0751V21.4423H48.8739V22.9482H48.9081C49.199 22.4457 49.6208 22.0315 50.1284 21.7497C50.636 21.4678 51.2106 21.3289 51.7909 21.3476C54.0888 21.3476 55.6034 23.164 55.6034 25.9697ZM53.6933 25.9697C53.6933 24.1367 52.746 22.9316 51.3007 22.9316C49.8808 22.9316 48.9257 24.162 48.9257 25.9697C48.9257 27.7939 49.8808 29.0156 51.3007 29.0156C52.746 29.0156 53.6933 27.8193 53.6933 25.9697Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61.79 30.5908C64.0624 30.5908 65.5688 28.7831 65.5688 25.9697C65.5688 23.164 64.0536 21.3476 61.7558 21.3476C61.1754 21.3289 60.6009 21.4678 60.0932 21.7497C59.5856 22.0315 59.1639 22.4457 58.8729 22.9482H58.8388V21.4423H57.0399V33.4911H58.8983V29.0068H58.9413C59.2195 29.5116 59.6342 29.928 60.138 30.2081C60.6418 30.4883 61.2143 30.6209 61.79 30.5908ZM61.2655 22.9316C62.7109 22.9316 63.6581 24.1367 63.6581 25.9697C63.6581 27.8193 62.7109 29.0156 61.2655 29.0156C59.8456 29.0156 58.8905 27.7939 58.8905 25.9697C58.8905 24.162 59.8456 22.9316 61.2655 22.9316Z" />
        <path d="M83.7904 21.4423V19.2998H81.9232V21.4423H80.6068V22.914H81.9232V28.1034C81.9232 29.8593 82.638 30.5478 84.471 30.5478C84.8171 30.5542 85.163 30.5254 85.5032 30.4618V28.9989C85.3004 29.024 85.0963 29.0384 84.8919 29.0419C84.1351 29.0419 83.7904 28.6806 83.7904 27.9052V22.914H85.512V21.4423H83.7904Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M86.5082 25.9697C86.5082 23.121 88.186 21.331 90.8022 21.331C93.4272 21.331 95.0971 23.121 95.0971 25.9697C95.0971 28.8261 93.436 30.6083 90.8022 30.6083C88.1694 30.6083 86.5082 28.8261 86.5082 25.9697ZM93.2036 25.9697C93.2036 24.0156 92.308 22.8623 90.8022 22.8623C89.2963 22.8623 88.4008 24.0244 88.4008 25.9697C88.4008 27.9316 89.2963 29.0761 90.8022 29.0761C92.308 29.0761 93.2036 27.9316 93.2036 25.9697Z" />
        <path d="M98.4018 21.4423H96.6294L96.6293 30.496H98.4877V25.1259C98.4572 24.8574 98.4851 24.5855 98.5695 24.3288C98.6539 24.0722 98.7929 23.8368 98.9769 23.6389C99.1608 23.441 99.3855 23.2853 99.6353 23.1824C99.8851 23.0795 100.154 23.0319 100.424 23.0429C100.707 23.0346 100.989 23.0726 101.259 23.1552V21.4169C101.05 21.3701 100.837 21.3468 100.623 21.3475C100.127 21.3286 99.6399 21.4809 99.2433 21.7788C98.8467 22.0767 98.5647 22.502 98.4448 22.9833H98.4018V21.4423Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M109.828 27.8369C109.578 29.4804 107.977 30.6083 105.929 30.6083C103.295 30.6083 101.661 28.8437 101.661 26.0126C101.661 23.1728 103.304 21.331 105.851 21.331C108.356 21.331 109.931 23.0517 109.931 25.7968V26.4335H103.537V26.5458C103.507 26.879 103.549 27.2147 103.659 27.5305C103.769 27.8464 103.945 28.1352 104.176 28.3777C104.406 28.6203 104.685 28.811 104.995 28.9373C105.305 29.0636 105.638 29.1226 105.972 29.1103C106.411 29.1514 106.852 29.0497 107.229 28.8203C107.605 28.5908 107.898 28.2459 108.063 27.8368L109.828 27.8369ZM103.545 25.1347H108.072C108.088 24.8351 108.043 24.5353 107.938 24.2541C107.834 23.9729 107.672 23.7163 107.463 23.5006C107.255 23.2848 107.004 23.1145 106.727 23.0003C106.449 22.886 106.151 22.8304 105.851 22.8369C105.548 22.8351 105.248 22.8932 104.968 23.0079C104.688 23.1227 104.434 23.2917 104.219 23.5054C104.005 23.719 103.835 23.973 103.719 24.2526C103.604 24.5323 103.545 24.8321 103.545 25.1347Z" />
        <path d="M60.4269 8.43696H59.5382V14.6977H60.4269V8.43696Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M38.2707 8.73095C38.6603 8.70299 39.0513 8.76185 39.4154 8.90328C39.7795 9.04471 40.1077 9.26519 40.3763 9.54882C40.6449 9.83244 40.8472 10.1721 40.9686 10.5434C41.09 10.9147 41.1275 11.3083 41.0783 11.6958C41.0783 13.602 40.0481 14.6978 38.2707 14.6978H36.1155V8.73095H38.2707ZM37.0422 13.8539H38.1672C38.4456 13.8706 38.7242 13.8249 38.9828 13.7203C39.2413 13.6157 39.4733 13.4548 39.6618 13.2492C39.8502 13.0436 39.9905 12.7986 40.0724 12.532C40.1542 12.2654 40.1756 11.9839 40.135 11.7079C40.1727 11.4331 40.1491 11.1533 40.066 10.8887C39.9829 10.624 39.8423 10.381 39.6542 10.1771C39.4661 9.97315 39.2353 9.81334 38.9782 9.7091C38.7211 9.60487 38.4442 9.55879 38.1672 9.57416H37.0422V13.8539Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2244 11.5677C42.1306 11.8498 42.0968 12.1484 42.1251 12.4443C42.0962 12.7405 42.1297 13.0395 42.2233 13.322C42.3168 13.6045 42.4685 13.8644 42.6685 14.0848C42.8685 14.3052 43.1124 14.4813 43.3845 14.6019C43.6566 14.7224 43.951 14.7847 44.2486 14.7847C44.5462 14.7847 44.8406 14.7224 45.1127 14.6019C45.3848 14.4813 45.6287 14.3052 45.8287 14.0848C46.0287 13.8644 46.1803 13.6045 46.2739 13.322C46.3675 13.0395 46.401 12.7405 46.3721 12.4443C46.4004 12.1484 46.3666 11.8498 46.2728 11.5677C46.1789 11.2857 46.0272 11.0263 45.8272 10.8063C45.6273 10.5864 45.3836 10.4106 45.1117 10.2903C44.8399 10.17 44.5459 10.1079 44.2486 10.1079C43.9513 10.1079 43.6573 10.17 43.3855 10.2903C43.1136 10.4106 42.8699 10.5864 42.67 10.8063C42.47 11.0263 42.3183 11.2857 42.2244 11.5677ZM44.2501 10.8974C45.0196 10.8974 45.4581 11.4682 45.4581 12.4443C45.4581 13.4243 45.0196 13.9946 44.2501 13.9946C43.4776 13.9946 43.0431 13.4282 43.0431 12.4443C43.0431 11.4682 43.4776 10.8974 44.2501 10.8974Z" />
        <path d="M51.0959 14.6977H52.0178L53.2541 10.1948H52.3655L51.5627 13.6308H51.4924L50.5666 10.1948H49.7141L48.7883 13.6308H48.7219L47.9153 10.1948H47.0139L48.2551 14.6977H49.1682L50.095 11.3813H50.1653L51.0959 14.6977Z" />
        <path d="M55.1536 10.1948H54.2982L54.2982 14.6977H55.1868V12.0634C55.1711 11.9154 55.1875 11.7656 55.2351 11.6245C55.2826 11.4834 55.3601 11.3542 55.4622 11.2458C55.5643 11.1374 55.6887 11.0524 55.8267 10.9965C55.9648 10.9407 56.1133 10.9154 56.262 10.9223C56.9193 10.9223 57.2337 11.2822 57.2337 12.0058V14.6977H58.1224V11.7827C58.1546 11.5653 58.1374 11.3435 58.0722 11.1337C58.007 10.9239 57.8954 10.7314 57.7457 10.5706C57.5961 10.4097 57.4121 10.2846 57.2075 10.2045C57.0029 10.1244 56.7829 10.0914 56.5638 10.1079C56.2842 10.0851 56.0045 10.1502 55.7636 10.294C55.5227 10.4378 55.3327 10.6532 55.2201 10.9101H55.1536V10.1948Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M61.6619 12.4443C61.6336 12.1484 61.6675 11.8498 61.7614 11.5677C61.8552 11.2856 62.007 11.0263 62.207 10.8063C62.4069 10.5863 62.6507 10.4105 62.9226 10.2903C63.1944 10.17 63.4884 10.1079 63.7857 10.1079C64.083 10.1079 64.377 10.17 64.6489 10.2903C64.9207 10.4105 65.1645 10.5863 65.3644 10.8063C65.5644 11.0263 65.7162 11.2856 65.81 11.5677C65.9039 11.8498 65.9378 12.1484 65.9095 12.4443C65.9383 12.7406 65.9048 13.0395 65.8112 13.3221C65.7176 13.6046 65.5659 13.8644 65.3659 14.0848C65.1659 14.3052 64.922 14.4813 64.6498 14.6019C64.3777 14.7224 64.0833 14.7847 63.7857 14.7847C63.4881 14.7847 63.1937 14.7224 62.9216 14.6019C62.6495 14.4813 62.4056 14.3052 62.2055 14.0848C62.0055 13.8644 61.8538 13.6046 61.7602 13.3221C61.6666 13.0395 61.6331 12.7406 61.6619 12.4443ZM64.9949 12.4443C64.9949 11.4682 64.5565 10.8974 63.7869 10.8974C63.0145 10.8974 62.5799 11.4682 62.5799 12.4443C62.5799 13.4282 63.0145 13.9946 63.7869 13.9946C64.5565 13.9946 64.9949 13.4243 64.9949 12.4443Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M66.8451 13.4243C66.8451 12.6137 67.4486 12.1464 68.5199 12.08L69.7396 12.0097V11.621C69.7396 11.1454 69.4251 10.8769 68.8177 10.8769C68.3216 10.8769 67.9779 11.059 67.8792 11.3774H67.0189C67.1097 10.6039 67.8372 10.1079 68.8587 10.1079C69.9876 10.1079 70.6243 10.6699 70.6243 11.621V14.6977H69.7689V14.0649H69.6986C69.5559 14.2919 69.3554 14.4769 69.1178 14.6011C68.8802 14.7253 68.6139 14.7843 68.346 14.7719C68.157 14.7915 67.9659 14.7714 67.7852 14.7127C67.6044 14.654 67.438 14.5581 67.2965 14.4311C67.1551 14.3041 67.0419 14.1489 66.9641 13.9755C66.8864 13.8021 66.8458 13.6143 66.8451 13.4243ZM69.7396 13.0395V12.663L68.64 12.7333C68.0199 12.7748 67.7386 12.9858 67.7386 13.3827C67.7386 13.788 68.0902 14.0239 68.5736 14.0239C68.7152 14.0382 68.8583 14.0239 68.9943 13.9818C69.1303 13.9397 69.2565 13.8707 69.3653 13.7789C69.4741 13.687 69.5633 13.5742 69.6276 13.4472C69.6919 13.3202 69.73 13.1815 69.7396 13.0395Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.7925 12.4443C71.7925 11.0214 72.524 10.1201 73.6617 10.1201C73.9431 10.1071 74.2224 10.1745 74.4669 10.3144C74.7114 10.4543 74.9111 10.6609 75.0425 10.9101H75.1089V8.43696H75.9976V14.6977H75.1461V13.9863H75.0757C74.9341 14.2338 74.7275 14.4378 74.4783 14.5763C74.229 14.7148 73.9466 14.7824 73.6617 14.7719C72.5162 14.772 71.7925 13.8706 71.7925 12.4443ZM72.7105 12.4443C72.7105 13.3994 73.1607 13.9741 73.9137 13.9741C74.6627 13.9741 75.1256 13.3911 75.1256 12.4482C75.1256 11.5097 74.6578 10.9184 73.9137 10.9184C73.1656 10.9184 72.7105 11.497 72.7105 12.4443Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M79.6745 12.4443C79.6462 12.1484 79.6801 11.8498 79.7739 11.5677C79.8678 11.2857 80.0195 11.0263 80.2195 10.8063C80.4194 10.5864 80.6631 10.4106 80.935 10.2903C81.2068 10.17 81.5008 10.1079 81.7981 10.1079C82.0954 10.1079 82.3893 10.17 82.6612 10.2903C82.933 10.4106 83.1767 10.5864 83.3767 10.8063C83.5766 11.0263 83.7284 11.2857 83.8222 11.5677C83.9161 11.8498 83.9499 12.1484 83.9216 12.4443C83.9505 12.7405 83.917 13.0395 83.8234 13.322C83.7298 13.6045 83.5782 13.8644 83.3782 14.0848C83.1782 14.3052 82.9343 14.4813 82.6622 14.6019C82.39 14.7224 82.0957 14.7847 81.7981 14.7847C81.5005 14.7847 81.2061 14.7224 80.934 14.6019C80.6619 14.4813 80.418 14.3052 80.218 14.0848C80.018 13.8644 79.8663 13.6045 79.7727 13.322C79.6791 13.0395 79.6457 12.7405 79.6745 12.4443ZM83.0075 12.4443C83.0075 11.4682 82.5691 10.8974 81.7995 10.8974C81.0271 10.8974 80.5925 11.4682 80.5925 12.4443C80.5925 13.4282 81.0271 13.9946 81.7995 13.9946C82.5691 13.9946 83.0075 13.4243 83.0075 12.4443Z" />
        <path d="M85.1137 10.1948H85.9692V10.9101H86.0356C86.1482 10.6532 86.3383 10.4379 86.5791 10.294C86.82 10.1502 87.0997 10.0852 87.3793 10.1079C87.5984 10.0914 87.8184 10.1245 88.023 10.2046C88.2276 10.2846 88.4116 10.4098 88.5613 10.5706C88.711 10.7314 88.8225 10.9239 88.8877 11.1337C88.9529 11.3435 88.9701 11.5653 88.9379 11.7827V14.6977H88.0493V12.0059C88.0493 11.2822 87.7348 10.9224 87.0776 10.9224C86.9288 10.9154 86.7803 10.9407 86.6423 10.9966C86.5042 11.0524 86.3799 11.1374 86.2777 11.2458C86.1756 11.3542 86.0981 11.4834 86.0506 11.6245C86.0031 11.7656 85.9866 11.9154 86.0024 12.0635V14.6977H85.1137V10.1948Z" />
        <path d="M93.9595 9.07368V10.2153H94.9351V10.9638H93.9595V13.2792C93.9595 13.7509 94.1539 13.9575 94.5962 13.9575C94.7095 13.9571 94.8226 13.9503 94.9351 13.937V14.6772C94.7755 14.7057 94.6138 14.7209 94.4517 14.7226C93.4634 14.7226 93.0699 14.3749 93.0699 13.5068V10.9638H92.355V10.2152H93.0699V9.07368H93.9595Z" />
        <path d="M96.1489 8.43696H97.0297V10.9184H97.1C97.2182 10.6591 97.4134 10.4425 97.6591 10.2981C97.9048 10.1538 98.189 10.0887 98.473 10.1118C98.691 10.0999 98.9089 10.1363 99.1112 10.2183C99.3134 10.3004 99.4951 10.4261 99.6433 10.5864C99.7914 10.7467 99.9023 10.9378 99.9681 11.1459C100.034 11.354 100.053 11.5741 100.024 11.7905V14.6977H99.1343V12.0097C99.1343 11.2905 98.7993 10.9262 98.1714 10.9262C98.0186 10.9137 97.865 10.9347 97.7212 10.9877C97.5774 11.0408 97.447 11.1246 97.339 11.2334C97.231 11.3421 97.1481 11.4731 97.096 11.6172C97.044 11.7614 97.024 11.9152 97.0376 12.0678V14.6977H96.1489L96.1489 8.43696Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M105.205 13.4819C105.085 13.8934 104.823 14.2494 104.466 14.4875C104.11 14.7257 103.681 14.8308 103.254 14.7846C102.958 14.7924 102.663 14.7356 102.39 14.6181C102.118 14.5006 101.874 14.3251 101.676 14.104C101.478 13.8829 101.331 13.6213 101.244 13.3375C101.157 13.0537 101.134 12.7544 101.174 12.4604C101.135 12.1655 101.159 11.8656 101.245 11.5809C101.332 11.2962 101.479 11.0335 101.675 10.8104C101.872 10.5873 102.115 10.4091 102.387 10.2879C102.658 10.1667 102.953 10.1053 103.25 10.1079C104.503 10.1079 105.259 10.9638 105.259 12.3779V12.6879H102.079V12.7377C102.066 12.903 102.086 13.0693 102.14 13.2261C102.195 13.3828 102.281 13.5265 102.394 13.648C102.507 13.7694 102.644 13.866 102.796 13.9314C102.948 13.9968 103.113 14.0296 103.279 14.0277C103.491 14.0533 103.707 14.015 103.897 13.9178C104.088 13.8206 104.246 13.6688 104.35 13.4818L105.205 13.4819ZM102.079 12.0307H104.354C104.365 11.8796 104.344 11.7278 104.294 11.5851C104.243 11.4423 104.162 11.3119 104.058 11.202C103.954 11.0921 103.827 11.0053 103.687 10.9472C103.547 10.889 103.397 10.8607 103.245 10.8642C103.092 10.8623 102.939 10.8911 102.797 10.9491C102.654 11.007 102.525 11.0929 102.416 11.2016C102.308 11.3103 102.222 11.4397 102.164 11.5821C102.106 11.7245 102.077 11.877 102.079 12.0307Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 5.33333C0 2.38781 2.38781 0 5.33333 0H114.667C117.612 0 120 2.38782 120 5.33333V34.6667C120 37.6122 117.612 40 114.667 40H5.33333C2.38781 40 0 37.6122 0 34.6667V5.33333ZM5.33333 0.888889H114.667C117.121 0.888889 119.111 2.87873 119.111 5.33333V34.6667C119.111 37.1213 117.121 39.1111 114.667 39.1111H5.33333C2.87873 39.1111 0.888889 37.1213 0.888889 34.6667V5.33333C0.888889 2.87873 2.87873 0.888889 5.33333 0.888889Z" />
      </g>
    </svg>
  );
};

const AppLinkQR = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="120"
    fill="none"
    viewBox="0 0 120 120"
  >
    <g fill={fillColor}>
      <path d="M41.3837 0.0165405H37.2469V4.15333H41.3837V0.0165405Z"/>
      <path d="M45.5205 0.0165405H41.3837V4.15333H45.5205V0.0165405Z"/>
      <path d="M49.6573 0.0165405H45.5205V4.15333H49.6573V0.0165405Z"/>
      <path d="M66.2044 0.0165405H62.0676V4.15333H66.2044V0.0165405Z"/>
      <path d="M70.3412 0.0165405H66.2044V4.15333H70.3412V0.0165405Z"/>
      <path d="M82.7514 0.0165405H78.6147V4.15333H82.7514V0.0165405Z"/>
      <path d="M45.5205 4.15333H41.3837L41.3837 8.29012H45.5205V4.15333Z"/>
      <path d="M74.4781 4.15333L70.3412 4.15333L70.3413 8.29012H74.4781V4.15333Z"/>
      <path d="M37.2468 8.29012H33.11V12.4269H37.2468V8.29012Z"/>
      <path d="M45.5205 8.29012H41.3837V12.4269H45.5205V8.29012Z"/>
      <path d="M49.6573 8.29012L45.5205 8.29012V12.4269H49.6573V8.29012Z"/>
      <path d="M62.0676 8.29012H57.9308V12.4269H62.0676V8.29012Z"/>
      <path d="M70.3413 8.29012L66.2044 8.29012V12.4269H70.3412L70.3413 8.29012Z"/>
      <path d="M74.4781 8.29012H70.3413L70.3412 12.4269H74.4781V8.29012Z"/>
      <path d="M78.6146 8.29012L74.4781 8.29012V12.4269H78.6146V8.29012Z"/>
      <path d="M82.7514 8.29012H78.6146V12.4269H82.7514V8.29012Z"/>
      <path d="M86.8883 8.29012H82.7514V12.4269H86.8883V8.29012Z"/>
      <path d="M37.2468 12.4269H33.11V16.5637H37.2468V12.4269Z"/>
      <path d="M41.3837 12.4269H37.2468V16.5637H41.3837L41.3837 12.4269Z"/>
      <path d="M70.3412 12.4269H66.2044V16.5637H70.3412V12.4269Z"/>
      <path d="M74.4781 12.4269H70.3412V16.5637H74.4781V12.4269Z"/>
      <path d="M37.2468 16.5637H33.11V20.7005H37.2468V16.5637Z"/>
      <path d="M41.3837 16.5637H37.2468V20.7005H41.3837V16.5637Z"/>
      <path d="M45.5205 16.5637L41.3837 16.5637V20.7005H45.5205V16.5637Z"/>
      <path d="M53.7939 16.5637H49.6571V20.7005H53.7939V16.5637Z"/>
      <path d="M57.9307 16.5637H53.7939V20.7005H57.9307V16.5637Z"/>
      <path d="M62.0676 16.5637H57.9307V20.7005H62.0676V16.5637Z"/>
      <path d="M66.2044 16.5637L62.0676 16.5637V20.7005H66.2044L66.2044 16.5637Z"/>
      <path d="M82.7514 16.5637H78.6147V20.7005H82.7514V16.5637Z"/>
      <path d="M86.8883 16.5637H82.7514V20.7005H86.8883V16.5637Z"/>
      <path d="M37.2468 20.7005H33.11V24.8373H37.2468V20.7005Z"/>
      <path d="M41.3837 20.7005H37.2468V24.8373H41.3837V20.7005Z"/>
      <path d="M45.5205 20.7005H41.3837V24.8373H45.5205V20.7005Z"/>
      <path d="M53.7939 20.7005H49.6571V24.8373H53.7939V20.7005Z"/>
      <path d="M57.9307 20.7005H53.7939V24.8373H57.9307V20.7005Z"/>
      <path d="M62.0676 20.7005H57.9307V24.8373H62.0676V20.7005Z"/>
      <path d="M66.2044 20.7005H62.0676V24.8373H66.2044V20.7005Z"/>
      <path d="M70.3412 20.7005L66.2044 20.7005V24.8373H70.3412V20.7005Z"/>
      <path d="M74.4781 20.7005H70.3412V24.8373H74.4781V20.7005Z"/>
      <path d="M82.7514 20.7005H78.6147V24.8373H82.7514V20.7005Z"/>
      <path d="M37.2468 24.8373H33.11V28.9741H37.2468V24.8373Z"/>
      <path d="M45.5205 24.8373H41.3837L41.3837 28.9741H45.5205V24.8373Z"/>
      <path d="M53.7939 24.8373H49.6571V28.9741H53.7939V24.8373Z"/>
      <path d="M62.0676 24.8373H57.9307L57.9308 28.9741H62.0676V24.8373Z"/>
      <path d="M70.3412 24.8373H66.2044L66.2044 28.9741H70.3412V24.8373Z"/>
      <path d="M78.6147 24.8373H74.4781L74.4778 28.9741H78.6146L78.6147 24.8373Z"/>
      <path d="M86.8883 24.8373L82.7514 24.8373L82.7515 28.9741H86.8883V24.8373Z"/>
      <path d="M37.2468 28.9741H33.11V33.1109H37.2468V28.9741Z"/>
      <path d="M41.3837 28.9741H37.2468V33.1109H41.3837L41.3837 28.9741Z"/>
      <path d="M49.6571 28.9741H45.5205L45.5205 33.1109H49.6573L49.6571 28.9741Z"/>
      <path d="M57.9308 28.9741H53.7939L53.7939 33.1109H57.9307L57.9308 28.9741Z"/>
      <path d="M66.2044 28.9741H62.0676L62.0676 33.1109H66.2044L66.2044 28.9741Z"/>
      <path d="M74.4778 28.9741H70.3412L70.3413 33.1109H74.4781L74.4778 28.9741Z"/>
      <path d="M78.6146 28.9741H74.4778L74.4781 33.1109H78.6146V28.9741Z"/>
      <path d="M82.7515 28.9741H78.6146V33.1109H82.7514L82.7515 28.9741Z"/>
      <path d="M4.15241 33.1109H0.015625V37.2477H4.15241V33.1109Z"/>
      <path d="M12.4261 33.1109H8.2893V37.2477H12.4261V33.1109Z"/>
      <path d="M16.5629 33.1109H12.4261V37.2477H16.5629V33.1109Z"/>
      <path d="M20.6998 33.1109H16.5629V37.2477H20.6998V33.1109Z"/>
      <path d="M24.8366 33.1109H20.6998V37.2477H24.8366V33.1109Z"/>
      <path d="M28.9731 33.1109H24.8366V37.2477H28.9731V33.1109Z"/>
      <path d="M41.3837 33.1109H37.2468L37.2469 37.2477H41.3837V33.1109Z"/>
      <path d="M45.5205 33.1109H41.3837V37.2477H45.5205L45.5205 33.1109Z"/>
      <path d="M53.7939 33.1109H49.6573L49.6571 37.2477H53.7939L53.7939 33.1109Z"/>
      <path d="M62.0676 33.1109H57.9307L57.9308 37.2477H62.0676L62.0676 33.1109Z"/>
      <path d="M66.2044 33.1109H62.0676L62.0676 37.2477H66.2044V33.1109Z"/>
      <path d="M95.162 33.1109H91.0252V37.2477H95.162V33.1109Z"/>
      <path d="M99.2988 33.1109H95.162V37.2477H99.2988V33.1109Z"/>
      <path d="M103.435 33.1109H99.2988V37.2477H103.435V33.1109Z"/>
      <path d="M107.572 33.1109H103.435V37.2477H107.572V33.1109Z"/>
      <path d="M111.709 33.1109H107.572V37.2477H111.709V33.1109Z"/>
      <path d="M8.2893 37.2477H4.15241L4.15246 41.3844H8.28925L8.2893 37.2477Z"/>
      <path d="M20.6998 37.2477H16.5629L16.563 41.3844H20.6998V37.2477Z"/>
      <path d="M33.11 37.2476L28.9731 37.2477L28.9732 41.3844H33.11V37.2476Z"/>
      <path d="M37.2469 37.2477L33.11 37.2476V41.3844H37.2468L37.2469 37.2477Z"/>
      <path d="M41.3837 37.2477H37.2469L37.2468 41.3844H41.3837V37.2477Z"/>
      <path d="M45.5205 37.2477H41.3837V41.3844H45.5205V37.2477Z"/>
      <path d="M53.7939 37.2477H49.6571V41.3844H53.7939V37.2477Z"/>
      <path d="M66.2044 37.2477H62.0676L62.0676 41.3844H66.2044V37.2477Z"/>
      <path d="M70.3412 37.2476L66.2044 37.2477V41.3844H70.3412V37.2476Z"/>
      <path d="M91.0252 37.2477L86.8883 37.2476V41.3844H91.0251L91.0252 37.2477Z"/>
      <path d="M95.162 37.2477H91.0252L91.0251 41.3844H95.162V37.2477Z"/>
      <path d="M103.435 37.2477H99.2988L99.2986 41.3844H103.435V37.2477Z"/>
      <path d="M107.572 37.2477H103.435V41.3844H107.572V37.2477Z"/>
      <path d="M115.846 37.2476L111.709 37.2477L111.709 41.3844H115.846V37.2476Z"/>
      <path d="M12.4261 41.3844L8.28925 41.3844L8.2893 45.5212H12.4261V41.3844Z"/>
      <path d="M16.563 41.3844L12.4261 41.3844V45.5212H16.5629L16.563 41.3844Z"/>
      <path d="M28.9732 41.3844L24.8364 41.3844V45.5212H28.9731L28.9732 41.3844Z"/>
      <path d="M33.11 41.3844H28.9732L28.9731 45.5212H33.11V41.3844Z"/>
      <path d="M37.2468 41.3844H33.11V45.5212H37.2468V41.3844Z"/>
      <path d="M41.3837 41.3844H37.2468V45.5212H41.3837V41.3844Z"/>
      <path d="M53.7939 41.3844H49.6571V45.5212H53.7939V41.3844Z"/>
      <path d="M74.4781 41.3844L70.3412 41.3844L70.3413 45.5212H74.4781V41.3844Z"/>
      <path d="M78.6146 41.3844H74.4781V45.5212H78.6146V41.3844Z"/>
      <path d="M86.8883 41.3844L82.7515 41.3844V45.5212H86.8883L86.8883 41.3844Z"/>
      <path d="M99.2986 41.3844H95.162L95.162 45.5212H99.2988L99.2986 41.3844Z"/>
      <path d="M107.572 41.3844H103.435L103.435 45.5212H107.572V41.3844Z"/>
      <path d="M111.709 41.3844H107.572V45.5212H111.709L111.709 41.3844Z"/>
      <path d="M4.15241 45.5212H0.015625V49.658H4.15241V45.5212Z"/>
      <path d="M8.2893 45.5212L4.15241 45.5212V49.658H8.28925L8.2893 45.5212Z"/>
      <path d="M12.4261 45.5212H8.2893L8.28925 49.658H12.4261V45.5212Z"/>
      <path d="M20.6998 45.5212L16.5629 45.5212L16.563 49.658H20.6998V45.5212Z"/>
      <path d="M37.2468 45.5212H33.11L33.11 49.658H37.2468V45.5212Z"/>
      <path d="M41.3837 45.5212H37.2468V49.658H41.3837V45.5212Z"/>
      <path d="M45.5205 45.5212L41.3837 45.5212V49.658H45.5205V45.5212Z"/>
      <path d="M53.7939 45.5212H49.6571V49.658H53.7939V45.5212Z"/>
      <path d="M62.0676 45.5212H57.9308V49.658H62.0676V45.5212Z"/>
      <path d="M70.3413 45.5212L66.2044 45.5212V49.658H70.3412L70.3413 45.5212Z"/>
      <path d="M74.4781 45.5212H70.3413L70.3412 49.658H74.4781V45.5212Z"/>
      <path d="M78.6146 45.5212H74.4781V49.658H78.6146V45.5212Z"/>
      <path d="M82.7515 45.5212H78.6146V49.658H82.7514L82.7515 45.5212Z"/>
      <path d="M86.8883 45.5212H82.7515L82.7514 49.658H86.8883V45.5212Z"/>
      <path d="M91.0251 45.5212L86.8883 45.5212V49.658H91.0251V45.5212Z"/>
      <path d="M95.162 45.5212L91.0251 45.5212V49.658H95.162L95.162 45.5212Z"/>
      <path d="M99.2988 45.5212H95.162L95.162 49.658H99.2988V45.5212Z"/>
      <path d="M103.435 45.5212H99.2988V49.658H103.435L103.435 45.5212Z"/>
      <path d="M107.572 45.5212H103.435L103.435 49.658H107.572V45.5212Z"/>
      <path d="M119.983 45.5212H115.846V49.658H119.983V45.5212Z"/>
      <path d="M8.28925 49.658H4.15241L4.15246 53.7948H8.28925V49.658Z"/>
      <path d="M16.563 49.658H12.4261L12.4261 53.7948H16.5629L16.563 49.658Z"/>
      <path d="M28.9731 49.658H24.8364V53.7948H28.9731V49.658Z"/>
      <path d="M41.3837 49.658H37.2468L37.2469 53.7948H41.3837V49.658Z"/>
      <path d="M45.5205 49.658H41.3837V53.7948H45.5205V49.658Z"/>
      <path d="M49.6571 49.658H45.5205V53.7948H49.6573L49.6571 49.658Z"/>
      <path d="M53.7939 49.658H49.6571L49.6573 53.7948H53.7939V49.658Z"/>
      <path d="M74.4781 49.658H70.3412L70.3413 53.7948H74.4781V49.658Z"/>
      <path d="M91.0251 49.658H86.8883L86.8883 53.7948H91.0251V49.658Z"/>
      <path d="M95.162 49.658H91.0251V53.7948H95.162V49.658Z"/>
      <path d="M99.2988 49.658H95.162V53.7948H99.2988V49.658Z"/>
      <path d="M103.435 49.658H99.2988V53.7948H103.435V49.658Z"/>
      <path d="M4.15246 53.7948L0.015625 53.7948V57.9316H4.15241L4.15246 53.7948Z"/>
      <path d="M12.4261 53.7948H8.28925L8.2893 57.9316H12.4261L12.4261 53.7948Z"/>
      <path d="M16.5629 53.7948H12.4261L12.4261 57.9316H16.5629V53.7948Z"/>
      <path d="M20.6998 53.7948L16.5629 53.7948V57.9316H20.6998V53.7948Z"/>
      <path d="M33.11 53.7948L28.9731 53.7948L28.9732 57.9316H33.11V53.7948Z"/>
      <path d="M45.5205 53.7948H41.3837L41.3837 57.9316H45.5205V53.7948Z"/>
      <path d="M49.6573 53.7948H45.5205V57.9316H49.6573V53.7948Z"/>
      <path d="M57.9307 53.7948L53.7939 53.7948L53.7939 57.9316H57.9307V53.7948Z"/>
      <path d="M62.0676 53.7948H57.9307V57.9316H62.0676V53.7948Z"/>
      <path d="M66.2044 53.7948H62.0676V57.9316H66.2044V53.7948Z"/>
      <path d="M99.2988 53.7948H95.162L95.162 57.9316H99.2988V53.7948Z"/>
      <path d="M103.435 53.7948H99.2988V57.9316H103.435V53.7948Z"/>
      <path d="M107.572 53.7948L103.435 53.7948V57.9316H107.572V53.7948Z"/>
      <path d="M115.846 53.7948H111.709V57.9316H115.846V53.7948Z"/>
      <path d="M4.15241 57.9316H0.015625V62.0684H4.15241V57.9316Z"/>
      <path d="M16.5629 57.9316H12.4261L12.4261 62.0684H16.5629V57.9316Z"/>
      <path d="M20.6998 57.9316H16.5629V62.0684H20.6998V57.9316Z"/>
      <path d="M24.8366 57.9316L20.6998 57.9316V62.0684H24.8366V57.9316Z"/>
      <path d="M28.9732 57.9316L24.8366 57.9316V62.0684H28.9731L28.9732 57.9316Z"/>
      <path d="M41.3837 57.9316L37.2469 57.9316V62.0684H41.3837L41.3837 57.9316Z"/>
      <path d="M45.5205 57.9316H41.3837L41.3837 62.0684H45.5205V57.9316Z"/>
      <path d="M53.7939 57.9316H49.6573L49.6571 62.0684H53.7939L53.7939 57.9316Z"/>
      <path d="M57.9307 57.9316H53.7939L53.7939 62.0684H57.9307V57.9316Z"/>
      <path d="M62.0676 57.9316H57.9307V62.0684H62.0676V57.9316Z"/>
      <path d="M66.2044 57.9316H62.0676V62.0684H66.2044V57.9316Z"/>
      <path d="M74.4781 57.9316H70.3413V62.0684H74.4781V57.9316Z"/>
      <path d="M86.8883 57.9316H82.7515V62.0684H86.8883V57.9316Z"/>
      <path d="M107.572 57.9316H103.435L103.435 62.0684H107.572V57.9316Z"/>
      <path d="M111.709 57.9316H107.572V62.0684H111.709L111.709 57.9316Z"/>
      <path d="M115.846 57.9316H111.709L111.709 62.0684H115.846V57.9316Z"/>
      <path d="M119.983 57.9316L115.846 57.9316V62.0684H119.983V57.9316Z"/>
      <path d="M16.5629 62.0684H12.4261V66.2052H16.5629V62.0684Z"/>
      <path d="M20.6998 62.0684H16.5629V66.2052H20.6998V62.0684Z"/>
      <path d="M24.8366 62.0684H20.6998V66.2052H24.8366V62.0684Z"/>
      <path d="M37.2469 62.0684L33.11 62.0684V66.2052H37.2468L37.2469 62.0684Z"/>
      <path d="M41.3837 62.0684H37.2469L37.2468 66.2052H41.3837V62.0684Z"/>
      <path d="M53.7939 62.0684H49.6571V66.2052H53.7939V62.0684Z"/>
      <path d="M57.9307 62.0684H53.7939V66.2052H57.9307V62.0684Z"/>
      <path d="M66.2044 62.0684H62.0676L62.0676 66.2052H66.2044V62.0684Z"/>
      <path d="M78.6146 62.0684L74.4781 62.0684L74.4778 66.2052H78.6146V62.0684Z"/>
      <path d="M82.7515 62.0684L78.6146 62.0684V66.2052H82.7514L82.7515 62.0684Z"/>
      <path d="M86.8883 62.0684H82.7515L82.7514 66.2052H86.8883V62.0684Z"/>
      <path d="M95.162 62.0684H91.0252V66.2052H95.162V62.0684Z"/>
      <path d="M99.2988 62.0684H95.162V66.2052H99.2988V62.0684Z"/>
      <path d="M103.435 62.0684L99.2988 62.0684V66.2052H103.435L103.435 62.0684Z"/>
      <path d="M115.846 62.0684H111.709L111.709 66.2052H115.846V62.0684Z"/>
      <path d="M119.983 62.0684H115.846V66.2052H119.983V62.0684Z"/>
      <path d="M8.28925 66.2052H4.15246V70.342H8.28925V66.2052Z"/>
      <path d="M12.4261 66.2052L8.28925 66.2052V70.342H12.4261L12.4261 66.2052Z"/>
      <path d="M16.5629 66.2052H12.4261L12.4261 70.342H16.5629V66.2052Z"/>
      <path d="M28.9731 66.2052L24.8366 66.2052L24.8364 70.342H28.9731V66.2052Z"/>
      <path d="M37.2468 66.2052H33.11V70.342H37.2468V66.2052Z"/>
      <path d="M41.3837 66.2052H37.2468V70.342H41.3837V66.2052Z"/>
      <path d="M49.6571 66.2052L45.5205 66.2052V70.342H49.6573L49.6571 66.2052Z"/>
      <path d="M62.0676 66.2052H57.9307L57.9308 70.342H62.0676L62.0676 66.2052Z"/>
      <path d="M66.2044 66.2052H62.0676L62.0676 70.342H66.2044V66.2052Z"/>
      <path d="M70.3412 66.2052L66.2044 66.2052V70.342H70.3412V66.2052Z"/>
      <path d="M78.6146 66.2052H74.4778V70.342H78.6146V66.2052Z"/>
      <path d="M86.8883 66.2052H82.7514L82.7515 70.342H86.8883V66.2052Z"/>
      <path d="M91.0252 66.2052H86.8883V70.342H91.0251L91.0252 66.2052Z"/>
      <path d="M103.435 66.2052H99.2988L99.2986 70.342H103.435V66.2052Z"/>
      <path d="M4.15246 70.342H0.015625V74.4788H4.15241L4.15246 70.342Z"/>
      <path d="M24.8364 70.342H20.6998V74.4788H24.8366L24.8364 70.342Z"/>
      <path d="M33.11 70.342H28.9731L28.9732 74.4788H33.11L33.11 70.342Z"/>
      <path d="M53.7939 70.342H49.6573L49.6571 74.4788H53.7939V70.342Z"/>
      <path d="M95.162 70.342H91.0251L91.0252 74.4788H95.162V70.342Z"/>
      <path d="M103.435 70.342H99.2986V74.4788H103.435V70.342Z"/>
      <path d="M107.572 70.342H103.435V74.4788H107.572V70.342Z"/>
      <path d="M119.983 70.342H115.846V74.4788H119.983V70.342Z"/>
      <path d="M4.15241 74.4788H0.015625V78.6156H4.15241V74.4788Z"/>
      <path d="M12.4261 74.4788H8.2893V78.6156H12.4261V74.4788Z"/>
      <path d="M16.5629 74.4788H12.4261V78.6156H16.5629V74.4788Z"/>
      <path d="M24.8366 74.4788H20.6998V78.6156H24.8366V74.4788Z"/>
      <path d="M28.9732 74.4788H24.8366V78.6156H28.9731L28.9732 74.4788Z"/>
      <path d="M33.11 74.4788H28.9732L28.9731 78.6156H33.11V74.4788Z"/>
      <path d="M37.2468 74.4788L33.11 74.4788V78.6156H37.2468V74.4788Z"/>
      <path d="M41.3837 74.4788H37.2468V78.6156H41.3837V74.4788Z"/>
      <path d="M45.5205 74.4788H41.3837V78.6156H45.5205V74.4788Z"/>
      <path d="M74.4781 74.4788H70.3413V78.6156H74.4781V74.4788Z"/>
      <path d="M99.2986 74.4788H95.162L95.162 78.6156H99.2988L99.2986 74.4788Z"/>
      <path d="M107.572 74.4788H103.435L103.435 78.6156H107.572V74.4788Z"/>
      <path d="M111.709 74.4788L107.572 74.4788V78.6156H111.709V74.4788Z"/>
      <path d="M119.983 74.4788H115.846V78.6156H119.983V74.4788Z"/>
      <path d="M4.15241 78.6156H0.015625V82.7523H4.15241V78.6156Z"/>
      <path d="M24.8366 78.6156H20.6998V82.7523H24.8366V78.6156Z"/>
      <path d="M37.2468 78.6156H33.11L33.11 82.7523H37.2468V78.6156Z"/>
      <path d="M45.5205 78.6156H41.3837L41.3837 82.7523H45.5205V78.6156Z"/>
      <path d="M53.7939 78.6155H49.6571V82.7523H53.7939V78.6155Z"/>
      <path d="M62.0676 78.6155H57.9308V82.7523H62.0676V78.6155Z"/>
      <path d="M70.3413 78.6156L66.2044 78.6155V82.7523H70.3412L70.3413 78.6156Z"/>
      <path d="M82.7514 78.6155H78.6147V82.7523H82.7514V78.6155Z"/>
      <path d="M86.8883 78.6155H82.7514V82.7523H86.8883V78.6155Z"/>
      <path d="M95.162 78.6156L91.0252 78.6155V82.7523H95.162L95.162 78.6156Z"/>
      <path d="M99.2988 78.6156H95.162L95.162 82.7523H99.2988V78.6156Z"/>
      <path d="M103.435 78.6156H99.2988V82.7523H103.435L103.435 78.6156Z"/>
      <path d="M4.15241 82.7523H0.015625V86.8891H4.15241V82.7523Z"/>
      <path d="M12.4261 82.7523H8.2893V86.8891H12.4261V82.7523Z"/>
      <path d="M16.5629 82.7523H12.4261V86.8891H16.5629V82.7523Z"/>
      <path d="M20.6998 82.7523L16.5629 82.7523V86.8891H20.6998L20.6998 82.7523Z"/>
      <path d="M24.8366 82.7523H20.6998L20.6998 86.8891H24.8366V82.7523Z"/>
      <path d="M28.9731 82.7523L24.8366 82.7523V86.8891H28.9731V82.7523Z"/>
      <path d="M37.2468 82.7523H33.11V86.8891H37.2468V82.7523Z"/>
      <path d="M53.7939 82.7523H49.6571V86.8891H53.7939V82.7523Z"/>
      <path d="M66.2044 82.7523H62.0676L62.0676 86.8891H66.2044L66.2044 82.7523Z"/>
      <path d="M78.6147 82.7523L74.4778 82.7523V86.8891H78.6146L78.6147 82.7523Z"/>
      <path d="M86.8883 82.7523H82.7514L82.7515 86.8891H86.8883V82.7523Z"/>
      <path d="M91.0252 82.7523H86.8883V86.8891H91.0251L91.0252 82.7523Z"/>
      <path d="M95.162 82.7523H91.0252L91.0251 86.8891H95.162V82.7523Z"/>
      <path d="M99.2988 82.7523H95.162V86.8891H99.2988V82.7523Z"/>
      <path d="M103.435 82.7523H99.2988V86.8891H103.435V82.7523Z"/>
      <path d="M107.572 82.7523L103.435 82.7523V86.8891H107.572V82.7523Z"/>
      <path d="M111.709 82.7523H107.572V86.8891H111.709V82.7523Z"/>
      <path d="M115.846 82.7523H111.709V86.8891H115.846V82.7523Z"/>
      <path d="M37.2468 86.8891H33.11V91.0259H37.2468V86.8891Z"/>
      <path d="M41.3837 86.8891L37.2468 86.8891V91.0259H41.3837V86.8891Z"/>
      <path d="M49.6571 86.8891L45.5205 86.8891V91.0259H49.6573L49.6571 86.8891Z"/>
      <path d="M53.7939 86.8891H49.6571L49.6573 91.0259H53.7939V86.8891Z"/>
      <path d="M57.9307 86.8891L53.7939 86.8891V91.0259H57.9307V86.8891Z"/>
      <path d="M62.0676 86.8891L57.9307 86.8891V91.0259H62.0676L62.0676 86.8891Z"/>
      <path d="M70.3412 86.8891L66.2044 86.8891L66.2044 91.0259H70.3412V86.8891Z"/>
      <path d="M78.6146 86.8891H74.4778V91.0259H78.6146V86.8891Z"/>
      <path d="M82.7515 86.8891H78.6146V91.0259H82.7514L82.7515 86.8891Z"/>
      <path d="M86.8883 86.8891H82.7515L82.7514 91.0259H86.8883V86.8891Z"/>
      <path d="M103.435 86.8891H99.2988L99.2986 91.0259H103.435V86.8891Z"/>
      <path d="M115.846 86.8891H111.709L111.709 91.0259H115.846V86.8891Z"/>
      <path d="M53.7939 91.0259H49.6573L49.6571 95.1627H53.7939V91.0259Z"/>
      <path d="M57.9307 91.0259H53.7939V95.1627H57.9307V91.0259Z"/>
      <path d="M62.0676 91.0259H57.9307V95.1627H62.0676V91.0259Z"/>
      <path d="M74.4778 91.0259H70.3412L70.3413 95.1627H74.4781L74.4778 91.0259Z"/>
      <path d="M82.7514 91.0259H78.6146L78.6147 95.1627H82.7514V91.0259Z"/>
      <path d="M86.8883 91.0259H82.7514V95.1627H86.8883V91.0259Z"/>
      <path d="M95.162 91.0259H91.0252V95.1627H95.162V91.0259Z"/>
      <path d="M103.435 91.0259H99.2986V95.1627H103.435V91.0259Z"/>
      <path d="M107.572 91.0259L103.435 91.0259V95.1627H107.572V91.0259Z"/>
      <path d="M111.709 91.0259L107.572 91.0259V95.1627H111.709L111.709 91.0259Z"/>
      <path d="M37.2468 95.1627H33.11V99.2995H37.2468V95.1627Z"/>
      <path d="M41.3837 95.1627H37.2468V99.2995H41.3837V95.1627Z"/>
      <path d="M57.9307 95.1627H53.7939L53.7939 99.2995H57.9307V95.1627Z"/>
      <path d="M70.3413 95.1627H66.2044V99.2995H70.3412L70.3413 95.1627Z"/>
      <path d="M86.8883 95.1627H82.7514L82.7515 99.2995H86.8883V95.1627Z"/>
      <path d="M103.435 95.1627H99.2986V99.2995H103.435V95.1627Z"/>
      <path d="M111.709 95.1627H107.572L107.572 99.2995H111.709V95.1627Z"/>
      <path d="M115.846 95.1627H111.709V99.2995H115.846V95.1627Z"/>
      <path d="M119.983 95.1627H115.846V99.2995H119.983V95.1627Z"/>
      <path d="M37.2468 99.2995H33.11V103.436H37.2468V99.2995Z"/>
      <path d="M45.5205 99.2995L41.3837 99.2995L41.3837 103.436H45.5205V99.2995Z"/>
      <path d="M49.6573 99.2995H45.5205V103.436H49.6573V99.2995Z"/>
      <path d="M53.7939 99.2995L49.6573 99.2995V103.436H53.7939L53.7939 99.2995Z"/>
      <path d="M62.0676 99.2995L57.9307 99.2995L57.9308 103.436H62.0676V99.2995Z"/>
      <path d="M66.2044 99.2995L62.0676 99.2995V103.436H66.2044L66.2044 99.2995Z"/>
      <path d="M70.3412 99.2995H66.2044L66.2044 103.436H70.3412V99.2995Z"/>
      <path d="M78.6146 99.2995H74.4778V103.436H78.6146V99.2995Z"/>
      <path d="M86.8883 99.2995H82.7515V103.436H86.8883V99.2995Z"/>
      <path d="M91.0251 99.2995L86.8883 99.2995V103.436H91.0251V99.2995Z"/>
      <path d="M95.162 99.2995H91.0251V103.436H95.162V99.2995Z"/>
      <path d="M99.2986 99.2995L95.162 99.2995V103.436H99.2988L99.2986 99.2995Z"/>
      <path d="M103.435 99.2995H99.2986L99.2988 103.436H103.435V99.2995Z"/>
      <path d="M111.709 99.2995H107.572V103.436H111.709V99.2995Z"/>
      <path d="M115.846 99.2995H111.709V103.436H115.846V99.2995Z"/>
      <path d="M119.983 99.2995H115.846V103.436H119.983V99.2995Z"/>
      <path d="M37.2468 103.436H33.11V107.573H37.2468V103.436Z"/>
      <path d="M41.3837 103.436H37.2468V107.573H41.3837L41.3837 103.436Z"/>
      <path d="M45.5205 103.436H41.3837L41.3837 107.573H45.5205V103.436Z"/>
      <path d="M49.6573 103.436H45.5205V107.573H49.6573V103.436Z"/>
      <path d="M57.9308 103.436H53.7939L53.7939 107.573H57.9307L57.9308 103.436Z"/>
      <path d="M66.2044 103.436H62.0676L62.0676 107.573H66.2044V103.436Z"/>
      <path d="M78.6146 103.436H74.4778V107.573H78.6146V103.436Z"/>
      <path d="M86.8883 103.436H82.7515V107.573H86.8883V103.436Z"/>
      <path d="M91.0251 103.436H86.8883V107.573H91.0251V103.436Z"/>
      <path d="M95.162 103.436H91.0251V107.573H95.162V103.436Z"/>
      <path d="M99.2988 103.436H95.162V107.573H99.2988V103.436Z"/>
      <path d="M37.2468 107.573H33.11V111.71H37.2468V107.573Z"/>
      <path d="M45.5205 107.573H41.3837L41.3837 111.71H45.5205V107.573Z"/>
      <path d="M49.6573 107.573H45.5205V111.71H49.6573V107.573Z"/>
      <path d="M53.7939 107.573H49.6573V111.71H53.7939L53.7939 107.573Z"/>
      <path d="M62.0676 107.573H57.9307L57.9308 111.71H62.0676L62.0676 107.573Z"/>
      <path d="M74.4778 107.573L70.3413 107.573V111.71H74.4781L74.4778 107.573Z"/>
      <path d="M82.7515 107.573H78.6146L78.6147 111.71H82.7514L82.7515 107.573Z"/>
      <path d="M99.2988 107.573H95.162L95.162 111.71H99.2988V107.573Z"/>
      <path d="M111.709 107.573H107.572V111.71H111.709V107.573Z"/>
      <path d="M115.846 107.573H111.709V111.71H115.846V107.573Z"/>
      <path d="M53.7939 111.71H49.6573L49.6571 115.847H53.7939V111.71Z"/>
      <path d="M62.0676 111.71H57.9308V115.847H62.0676V111.71Z"/>
      <path d="M70.3413 111.71L66.2044 111.71V115.847H70.3412L70.3413 111.71Z"/>
      <path d="M74.4781 111.71H70.3413L70.3412 115.847H74.4781V111.71Z"/>
      <path d="M78.6147 111.71H74.4781V115.847H78.6146L78.6147 111.71Z"/>
      <path d="M86.8883 111.71L82.7514 111.71L82.7515 115.847H86.8883V111.71Z"/>
      <path d="M107.572 111.71L103.435 111.71V115.847H107.572L107.572 111.71Z"/>
      <path d="M111.709 111.71H107.572L107.572 115.847H111.709V111.71Z"/>
      <path d="M119.983 111.71L115.846 111.71L115.846 115.847H119.983V111.71Z"/>
      <path d="M37.2468 115.847H33.11V119.983H37.2468V115.847Z"/>
      <path d="M49.6571 115.847L45.5205 115.847V119.983H49.6573L49.6571 115.847Z"/>
      <path d="M57.9308 115.847H53.7939L53.7939 119.983H57.9307L57.9308 115.847Z"/>
      <path d="M62.0676 115.847H57.9308L57.9307 119.983H62.0676V115.847Z"/>
      <path d="M66.2044 115.847H62.0676V119.983H66.2044L66.2044 115.847Z"/>
      <path d="M82.7515 115.847H78.6146L78.6147 119.983H82.7514L82.7515 115.847Z"/>
      <path d="M86.8883 115.847H82.7515L82.7514 119.983H86.8883V115.847Z"/>
      <path d="M91.0251 115.847L86.8883 115.847V119.983H91.0251V115.847Z"/>
      <path d="M95.162 115.847H91.0251V119.983H95.162V115.847Z"/>
      <path d="M103.435 115.847L99.2986 115.847V119.983H103.435L103.435 115.847Z"/>
      <path d="M107.572 115.847H103.435L103.435 119.983H107.572V115.847Z"/>
      <path d="M111.709 115.847H107.572V119.983H111.709V115.847Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.015625 14.4953C0.015625 6.49891 6.49799 0.0165405 14.4944 0.0165405C22.4908 0.0165405 28.9732 6.49891 28.9732 14.4953C28.9732 22.4917 22.4908 28.9741 14.4944 28.9741C6.49799 28.9741 0.015625 22.4917 0.015625 14.4953ZM4.15241 14.4953C4.15241 8.78359 8.78267 4.15333 14.4944 4.15333C20.2061 4.15333 24.8364 8.78359 24.8364 14.4953C24.8364 20.207 20.2061 24.8373 14.4944 24.8373C8.78267 24.8373 4.15241 20.207 4.15241 14.4953Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M91.0252 14.4953C91.0252 6.49891 97.5075 0.0165405 105.504 0.0165405C113.5 0.0165405 119.983 6.49891 119.983 14.4953C119.983 22.4917 113.5 28.9741 105.504 28.9741C97.5075 28.9741 91.0252 22.4917 91.0252 14.4953ZM95.162 14.4953C95.162 8.78359 99.7922 4.15333 105.504 4.15333C111.216 4.15333 115.846 8.78359 115.846 14.4953C115.846 20.207 111.216 24.8373 105.504 24.8373C99.7922 24.8373 95.162 20.207 95.162 14.4953Z"/>
      <path d="M8.2893 14.4953C8.2893 17.9223 11.0675 20.7005 14.4945 20.7005C17.9215 20.7005 20.6997 17.9223 20.6997 14.4953C20.6997 11.0683 17.9215 8.29012 14.4945 8.29012C11.0675 8.29012 8.2893 11.0683 8.2893 14.4953Z"/>
      <path d="M99.2986 14.4953C99.2986 17.9223 102.077 20.7005 105.504 20.7005C108.931 20.7005 111.709 17.9223 111.709 14.4953C111.709 11.0683 108.931 8.29012 105.504 8.29012C102.077 8.29012 99.2986 11.0683 99.2986 14.4953Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.015625 105.505C0.015625 97.5083 6.49799 91.0259 14.4944 91.0259C22.4908 91.0259 28.9732 97.5083 28.9732 105.505C28.9732 113.501 22.4908 119.983 14.4944 119.983C6.49799 119.983 0.015625 113.501 0.015625 105.505ZM4.15241 105.505C4.15241 99.793 8.78267 95.1627 14.4944 95.1627C20.2061 95.1627 24.8364 99.793 24.8364 105.505C24.8364 111.216 20.2061 115.847 14.4944 115.847C8.78267 115.847 4.15241 111.216 4.15241 105.505Z"/>
      <path d="M8.2893 105.505C8.2893 108.932 11.0675 111.71 14.4945 111.71C17.9215 111.71 20.6997 108.932 20.6997 105.505C20.6997 102.078 17.9215 99.2995 14.4945 99.2995C11.0675 99.2995 8.2893 102.078 8.2893 105.505Z"/>
    </g>
  </svg>
  );
};

const Apple = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M22 3C21.3125 3 20.5938 3.1875 19.8438 3.5625C19.0938 3.9375 18.4688 4.40625 17.9688 4.96875C16.7813 6.34375 16.3438 7.68749 16.6563 9C17.3438 9.0625 18.0313 8.90625 18.7188 8.53125C19.4063 8.15625 20 7.6875 20.5 7.125C21.5625 5.9375 22.0625 4.5625 22 3ZM12.5312 10.5937C10.9687 10.5937 9.65625 11.2188 8.59375 12.4688C7.53125 13.7188 7 15.3125 7 17.25C7 18.8125 7.34375 20.4063 8.03125 22.0312C8.59375 23.4687 9.34375 24.6562 10.2813 25.5938C11.2188 26.5313 12.0625 27 12.8125 27C13.25 27 13.7813 26.875 14.4063 26.625C15.1563 26.25 15.8281 26.0625 16.4219 26.0625C17.0156 26.0625 17.6562 26.2187 18.3437 26.5312C19.0312 26.8437 19.5938 27 20.0312 27C20.8437 27 21.6562 26.5312 22.4687 25.5938C23.0937 24.9062 23.6875 24 24.25 22.875C24.6875 22.0625 24.9375 21.5313 25 21.2813V21.1875C23 20.25 22 18.8125 22 16.875C22 15.6875 22.4062 14.6563 23.2187 13.7813C23.6562 13.2813 24.0625 12.9375 24.4375 12.75C23.875 11.875 23.125 11.2813 22.1875 10.9688C21.5625 10.7188 20.9219 10.5938 20.2656 10.5938C19.6094 10.5938 18.6875 10.7813 17.5 11.1563C16.8125 11.4063 16.3438 11.5313 16.0938 11.5313C15.8438 11.5313 15.4063 11.4063 14.7813 11.1563C13.7813 10.7813 13.0313 10.5937 12.5312 10.5937Z"/>
      </g>
    </svg>
  );
};

const Google = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M16 4C13.8333 4 11.8194 4.54167 9.95834 5.625C8.12501 6.68055 6.68057 8.125 5.625 9.95834C4.54167 11.8195 4 13.8333 4 16C4 18.1667 4.54167 20.1806 5.625 22.0417C6.68055 23.875 8.125 25.3194 9.95834 26.375C11.8195 27.4583 13.8333 28 16 28C18.1667 28 20.1806 27.4583 22.0417 26.375C23.875 25.3194 25.3194 23.875 26.375 22.0417C27.4583 20.1805 28 18.1667 28 16C28 15.1944 27.9167 14.3889 27.75 13.5833L27.7083 13.3333H16V17.3333H24C23.7778 18.5833 23.2847 19.7153 22.5208 20.7292C21.7569 21.7431 20.8055 22.5417 19.6667 23.125C18.5278 23.7083 17.3056 24 16 24C14.5556 24 13.2222 23.6389 12 22.9167C10.7778 22.1944 9.80558 21.2222 9.08335 20C8.36113 18.7778 8.00002 17.4445 8.00002 16C8.00002 14.5556 8.36113 13.2222 9.08335 12C9.80558 10.7778 10.7778 9.80557 12 9.08334C13.2222 8.36112 14.5556 8.00001 16 8.00001C16.9722 8.00001 17.9097 8.17362 18.8125 8.52084C19.7153 8.86807 20.5139 9.34723 21.2083 9.95834L24.0833 7.12501C23 6.15279 21.7639 5.3889 20.375 4.83334C18.9861 4.27779 17.5278 4 16 4Z"/>
      </g>
    </svg>
  );
};

const Facebook = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M19.0975 4.00001C17.3653 4.00001 16.0528 4.46429 15.1599 5.39286C14.2135 6.37501 13.7403 7.85715 13.7403 9.83929V13.4286H9.45459V17.7143H13.7403V28H18.026V17.7143H21.4546L22.3117 13.4286H18.026V10.5625C18.026 9.77679 18.1733 9.2009 18.468 8.83483C18.7626 8.46875 19.2582 8.28572 19.9546 8.28572H22.3117V4.1875C21.4367 4.0625 20.3653 4.00001 19.0975 4.00001Z"/>
      </g>
    </svg>
  );
};

const ArrowRight = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M15.2929 26.2929C14.9024 26.6834 14.9024 27.3166 15.2929 27.7071C15.6834 28.0976 16.3166 28.0976 16.7071 27.7071L27.7071 16.7071C28.0976 16.3166 28.0976 15.6834 27.7071 15.2929L16.7071 4.29289C16.3166 3.90237 15.6834 3.90237 15.2929 4.29289C14.9024 4.68342 14.9024 5.31658 15.2929 5.70711L24.5858 15H5C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17H24.5858L15.2929 26.2929Z"/>
      </g>
    </svg>
  );
};

const ArrowLeft = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M15.2929 27.7071C15.6834 28.0976 16.3166 28.0976 16.7071 27.7071C17.0976 27.3166 17.0976 26.6834 16.7071 26.2929L7.41421 17H27C27.5523 17 28 16.5523 28 16C28 15.4477 27.5523 15 27 15H7.41421L16.7071 5.70711C17.0976 5.31658 17.0976 4.68342 16.7071 4.29289C16.3166 3.90237 15.6834 3.90237 15.2929 4.29289L4.29289 15.2929C3.90237 15.6834 3.90237 16.3166 4.29289 16.7071L15.2929 27.7071Z"/>
      </g>
    </svg>
  );
};

const Checked = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7071 7.29289C28.0976 7.68342 28.0976 8.31658 27.7071 8.70711L12.7071 23.7071C12.3166 24.0976 11.6834 24.0976 11.2929 23.7071L4.29289 16.7071C3.90237 16.3166 3.90237 15.6834 4.29289 15.2929C4.68342 14.9024 5.31658 14.9024 5.70711 15.2929L12 21.5858L26.2929 7.29289C26.6834 6.90237 27.3166 6.90237 27.7071 7.29289Z"
        />
      </g>
    </svg>
  );
};

const Delete = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7C12 6.44772 12.4477 6 13 6H19C19.5523 6 20 6.44772 20 7V8H12V7ZM10 8V7C10 5.34315 11.3431 4 13 4H19C20.6569 4 22 5.34315 22 7V8H23.9878H24.0099H27C27.5523 8 28 8.44772 28 9C28 9.55228 27.5523 10 27 10H24.8951L23.2905 24.4418C23.0654 26.4675 21.3532 28 19.315 28H12.6852C10.647 28 8.93476 26.4675 8.70968 24.4418L7.10504 10H5C4.44772 10 4 9.55228 4 9C4 8.44772 4.44772 8 5 8H7.99027H8.01237H10ZM21 10H11H9.11735L10.6974 24.2209C10.81 25.2338 11.6661 26 12.6852 26H19.315C20.3341 26 21.1902 25.2338 21.3027 24.2209L22.8828 10H21ZM14 12C14.5523 12 15 12.4477 15 13V22C15 22.5523 14.5523 23 14 23C13.4477 23 13 22.5523 13 22V13C13 12.4477 13.4477 12 14 12ZM19 13C19 12.4477 18.5523 12 18 12C17.4477 12 17 12.4477 17 13V22C17 22.5523 17.4477 23 18 23C18.5523 23 19 22.5523 19 22V13Z"
        />
      </g>
    </svg>
  );
};


const Visibility = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path d="M15.9957 21.539C17.4992 21.539 18.7758 21.0001 19.8257 19.9223C20.8756 18.8445 21.4005 17.5358 21.4005 15.9961C21.4005 14.4564 20.8743 13.149 19.8219 12.0738C18.7695 10.9986 17.4916 10.461 15.9881 10.461C14.4846 10.461 13.208 10.9999 12.1581 12.0777C11.1082 13.1555 10.5833 14.4642 10.5833 16.0039C10.5833 17.5436 11.1095 18.851 12.1619 19.9262C13.2143 21.0014 14.4923 21.539 15.9957 21.539ZM15.988 19.5547C15.0228 19.5547 14.2036 19.2087 13.5305 18.5168C12.8574 17.8248 12.5209 16.9845 12.5209 15.996C12.5209 15.0075 12.8587 14.1686 13.5344 13.4793C14.2101 12.79 15.0305 12.4453 15.9958 12.4453C16.9611 12.4453 17.7802 12.7913 18.4533 13.4833C19.1264 14.1752 19.4629 15.0155 19.4629 16.004C19.4629 16.9925 19.1251 17.8314 18.4494 18.5207C17.7738 19.21 16.9533 19.5547 15.988 19.5547ZM15.9959 26C13.0258 26 10.3255 25.1741 7.89479 23.5222C5.46413 21.8703 3.5761 19.698 2.23073 17.0054C2.14788 16.8746 2.08872 16.7177 2.05324 16.5347C2.01775 16.3517 2 16.1718 2 15.995C2 15.8182 2.01775 15.6396 2.05324 15.4591C2.08872 15.2786 2.14788 15.1211 2.23073 14.9866C3.576 12.3009 5.46268 10.1317 7.89078 8.47901C10.3189 6.82634 13.0179 6 15.988 6C18.958 6 21.6584 6.82634 24.0891 8.47901C26.5198 10.1317 28.4132 12.3009 29.7693 14.9866C29.8413 15.1211 29.8978 15.2798 29.9387 15.4628C29.9796 15.6458 30 15.8257 30 16.0025C30 16.1792 29.9796 16.3579 29.9387 16.5384C29.8978 16.719 29.8413 16.8746 29.7693 17.0054C28.4131 19.698 26.521 21.8703 24.093 23.5222C21.6649 25.1741 18.9659 26 15.9959 26ZM15.9871 23.9104C18.5888 23.9104 20.9756 23.1918 23.1474 21.7546C25.3191 20.3173 26.9718 18.3991 28.1054 16C26.9718 13.6009 25.3207 11.6827 23.1521 10.2454C20.9835 8.80818 18.5984 8.08955 15.9967 8.08955C13.395 8.08955 11.0082 8.80818 8.83645 10.2454C6.66466 11.6827 5.0012 13.6009 3.84606 16C5.0012 18.3991 6.66307 20.3173 8.83169 21.7546C11.0003 23.1918 13.3854 23.9104 15.9871 23.9104Z"/>
      </g>
    </svg>
  );
};

const EmailSent = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g fill={fillColor}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4 10C4 7.79086 5.79086 6 8 6H24C26.2091 6 28 7.79086 28 10V23C28 25.2091 26.2091 27 24 27H8C5.79086 27 4 25.2091 4 23H13C13.5523 23 14 22.5523 14 22C14 21.4477 13.5523 21 13 21H1C0.447715 21 0 21.4477 0 22C0 22.5523 0.447715 23 1 23H2C2 26.3137 4.68629 29 8 29H24C27.3137 29 30 26.3137 30 23V10C30 6.68629 27.3137 4 24 4H8C4.68629 4 2 6.68629 2 10V17H1C0.447715 17 0 17.4477 0 18C0 18.5523 0.447715 19 1 19H5C5.55228 19 6 18.5523 6 18C6 17.4477 5.55228 17 5 17H4V10ZM13.1716 14.7574L8.70711 10.2929C8.31658 9.90237 7.68342 9.90237 7.29289 10.2929C6.90237 10.6834 6.90237 11.3166 7.29289 11.7071L11.7574 16.1716C14.1005 18.5147 17.8995 18.5147 20.2426 16.1716L24.7071 11.7071C25.0976 11.3166 25.0976 10.6834 24.7071 10.2929C24.3166 9.90237 23.6834 9.90237 23.2929 10.2929L18.8284 14.7574C17.2663 16.3195 14.7337 16.3195 13.1716 14.7574Z"/>
      </g>
    </svg>
  );
};

const GooglePlay = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="135"
      height="40"
      fill="none"
      viewBox="0 0 135 40"
    >
      <g fill={fillColor}>
        <path d="M44.2139 13.06C44.9732 13.06 45.6127 12.87 45.9424 12.75V10.03H44.084V10.62H45.233V12.3C45.0632 12.38 44.7434 12.45 44.2638 12.45C42.9449 12.45 42.0856 11.59 42.0856 10.17C42.0856 8.75004 42.9849 7.90004 44.3538 7.90004C44.9233 7.90004 45.303 8.01004 45.6027 8.14004L45.7826 7.55004C45.5328 7.43004 45.0232 7.29004 44.3737 7.29004C42.4753 7.29004 41.3163 8.53004 41.3163 10.21C41.3163 11.09 41.616 11.84 42.1056 12.31C42.6552 12.84 43.3646 13.06 44.2139 13.06Z" />
        <path d="M50.1387 10.3501H47.9405V12.3901H50.3885V13.0001H47.2211V7.3501H50.2686V7.9601H47.9505V9.7401H50.1487V10.3401L50.1387 10.3501Z" />
        <path d="M50.998 7.97007H52.7065V13.0001H53.4459V7.97007H55.1745V7.35007H50.998V7.97007Z" />
        <path d="M58.6418 7.3501V13.0001H57.9124V7.3501H58.6418Z" />
        <path d="M59.4311 7.9701H61.1397V13.0001H61.8791V7.9701H63.6077V7.3501H59.4311V7.9701Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M71.1713 10.12C71.1713 12.06 69.9923 13.09 68.5535 13.09C67.1146 13.09 66.0155 11.93 66.0155 10.23C66.0155 8.44001 67.1246 7.26001 68.6334 7.26001C70.1422 7.26001 71.1713 8.44001 71.1713 10.12ZM66.7949 10.21C66.7949 11.42 67.4444 12.5 68.5934 12.5C69.7425 12.5 70.4019 11.44 70.4019 10.15C70.4019 9.03001 69.8124 7.85001 68.6034 7.85001C67.3944 7.85001 66.7949 8.96001 66.7949 10.2V10.21Z" />
        <path d="M72.2908 7.3501V13.0001H72.9802V10.5901C72.9802 9.6501 72.9602 8.9701 72.9202 8.2501H72.9502C73.24 8.8601 73.6197 9.5001 74.0093 10.1301L75.7979 13.0001H76.5373V7.3501H75.8478V9.7101C75.8478 10.5901 75.8578 11.2801 75.9278 12.0401H75.9078C75.648 11.4701 75.3183 10.8701 74.8986 10.2101L73.0901 7.3501H72.2908Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M68.0839 21.75C65.7358 21.75 63.8174 23.54 63.8174 26C63.8174 28.46 65.7358 30.25 68.0839 30.25C70.4319 30.25 72.3504 28.45 72.3504 26C72.3504 23.55 70.4319 21.75 68.0839 21.75ZM68.0839 28.58C66.7949 28.58 65.6858 27.52 65.6858 26C65.6858 24.48 66.7949 23.42 68.0839 23.42C69.3728 23.42 70.4819 24.47 70.4819 26C70.4819 27.53 69.3728 28.58 68.0839 28.58Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M58.7815 21.75C56.4335 21.75 54.515 23.54 54.515 26C54.515 28.46 56.4335 30.25 58.7815 30.25C61.1296 30.25 63.048 28.45 63.048 26C63.048 23.55 61.1296 21.75 58.7815 21.75ZM58.7815 28.58C57.4926 28.58 56.3835 27.52 56.3835 26C56.3835 24.48 57.4926 23.42 58.7815 23.42C60.0705 23.42 61.1796 24.47 61.1796 26C61.1796 27.53 60.0705 28.58 58.7815 28.58Z" />
        <path d="M47.7106 23.06V24.85H52.0271C51.8972 25.86 51.5575 26.61 51.0479 27.12C50.4184 27.75 49.4392 28.44 47.7206 28.44C45.0628 28.44 42.9845 26.3 42.9845 23.64C42.9845 20.98 45.0628 18.84 47.7206 18.84C49.1495 18.84 50.1986 19.4 50.968 20.13L52.2369 18.86C51.1578 17.83 49.729 17.04 47.7106 17.04C44.0736 17.04 41.0162 20 41.0162 23.65C41.0162 27.3 44.0736 30.26 47.7106 30.26C49.679 30.26 51.1578 29.62 52.3169 28.41C53.5059 27.22 53.8756 25.54 53.8756 24.19C53.8756 23.77 53.8456 23.38 53.7757 23.06H47.7106Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M92.9834 24.45C92.6337 23.5 91.5546 21.74 89.3464 21.74C87.1382 21.74 85.3397 23.46 85.3397 25.99C85.3397 28.37 87.1382 30.24 89.5562 30.24C91.5046 30.24 92.6337 29.05 93.0933 28.36L91.6445 27.39C91.1649 28.1 90.5054 28.57 89.5562 28.57C88.607 28.57 87.9275 28.14 87.4979 27.28L93.1832 24.93L92.9934 24.45H92.9834ZM87.1882 25.87C87.1382 24.23 88.4571 23.39 89.4063 23.39C90.1457 23.39 90.7752 23.76 90.985 24.29L87.1882 25.87Z" />
        <path d="M82.572 29.99H84.4404V17.49H82.572V29.99Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M79.5145 22.69H79.4545C79.0349 22.19 78.2355 21.74 77.2164 21.74C75.0881 21.74 73.1397 23.61 73.1397 26.01C73.1397 28.41 75.0881 30.25 77.2164 30.25C78.2255 30.25 79.0349 29.8 79.4545 29.28H79.5145V29.89C79.5145 31.52 78.6452 32.39 77.2463 32.39C76.1073 32.39 75.3979 31.57 75.1081 30.88L73.4794 31.56C73.9491 32.69 75.188 34.07 77.2463 34.07C79.4345 34.07 81.283 32.78 81.283 29.64V22H79.5145V22.69ZM77.3762 28.57C76.0873 28.57 75.0082 27.49 75.0082 26.01C75.0082 24.53 76.0873 23.42 77.3762 23.42C78.6652 23.42 79.6444 24.52 79.6444 26.01C79.6444 27.5 78.6452 28.57 77.3762 28.57Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M97.2699 17.49V29.99H99.1383V25.25H101.746C103.814 25.25 105.843 23.75 105.843 21.37C105.843 18.99 103.814 17.49 101.746 17.49H97.2699ZM101.786 23.52H99.1383V19.23H101.786C103.185 19.23 103.974 20.39 103.974 21.37C103.974 22.35 103.185 23.51 101.786 23.51V23.52Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M113.307 21.72C111.958 21.72 110.559 22.32 109.979 23.63L111.638 24.32C111.988 23.63 112.647 23.4 113.337 23.4C114.296 23.4 115.285 23.98 115.295 25.01V25.14C114.955 24.95 114.236 24.66 113.347 24.66C111.558 24.66 109.75 25.64 109.75 27.47C109.75 29.14 111.208 30.22 112.847 30.22C114.096 30.22 114.795 29.66 115.225 29H115.285V29.96H117.084V25.17C117.084 22.95 115.425 21.71 113.287 21.71L113.307 21.72ZM113.077 28.57C112.467 28.57 111.618 28.26 111.618 27.51C111.618 26.55 112.677 26.18 113.596 26.18C114.416 26.18 114.805 26.36 115.295 26.6C115.155 27.76 114.156 28.58 113.077 28.58V28.57Z" />
        <path d="M123.648 21.99L121.51 27.41H121.45L119.232 21.99H117.223L120.551 29.57L118.652 33.78H120.601L125.726 21.99H123.648Z" />
        <path d="M106.852 29.99H108.72V17.49H106.852V29.99Z" />
        <path d="M31.4546 17.4999L26.6766 14.7158L21.3967 20.0001L26.6977 25.3054L31.4546 22.5599C32.3539 22.0699 32.9634 21.1199 32.9634 20.0299C32.9634 18.9399 32.3638 17.9899 31.4646 17.5099V17.4999H31.4546Z" />
        <path d="M14.3287 7.54989L25.7827 14.1962L20.6902 19.293L10.2256 8.81973C10.6806 7.8327 11.6804 7.13989 12.8399 7.13989C13.3895 7.13989 13.899 7.28989 14.3287 7.54989Z" />
        <path d="M9.96229 10.0199C9.96229 10.0035 9.96245 9.98717 9.96276 9.97093L19.9837 20.0001L9.96252 30.0295L9.96229 29.9899V10.0199Z" />
        <path d="M12.8399 32.8499C11.6824 32.8499 10.6841 32.1671 10.2279 31.1781L20.6902 20.7072L25.7915 25.8127L14.3287 32.4299L14.2987 32.4499C13.8691 32.7099 13.3695 32.8499 12.8399 32.8499Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.99589 0H130.004C132.752 0 135 2.25 135 5V35C135 37.75 132.752 40 130.004 40H4.99589C2.24815 40 0 37.75 0 35V5C0 2.25 2.24815 0 4.99589 0ZM134.201 5C134.201 2.68 132.322 0.8 130.004 0.8H4.99589C2.6778 0.8 0.799342 2.68 0.799342 5V35C0.799342 37.32 2.6778 39.2 4.99589 39.2H130.004C132.322 39.2 134.201 37.32 134.201 35V5Z" />
      </g>
    </svg>
  );
};

const Logo = (props) => {
  ColorSwitch(props.color);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="48"
      fill="none"
      viewBox="0 0 180 48"
    >
      <g fill={fillColor}>
        <path d="M75.2898 19.1711C75.2898 22.3711 73.5253 25.06 69.0515 25.06H62.4482V33.3956H59.8933V13.3333L69.1746 13.3133C73.5253 13.3133 75.292 15.94 75.292 19.1733L75.2898 19.1711ZM72.6409 19.1711C72.6409 16.7844 71.5459 15.3356 68.6238 15.3356H62.4459V23.0378H68.532C71.5437 23.0378 72.6409 21.4667 72.6409 19.1711Z"  />
        <path d="M89.1928 28.7444H91.414C91.1095 31.04 89.345 33.8778 84.2935 33.8778C79.2419 33.8778 76.7475 30.3733 76.7475 25.8133C76.7475 21.2533 79.2419 17.8089 84.2935 17.8089C88.8591 17.8089 91.4454 20.92 91.4454 25.3289C91.4454 25.7222 91.4454 26.0222 91.3849 26.3867H79.1524C79.336 30.5244 81.3445 32.0333 84.3875 32.0333C87.4306 32.0333 88.7382 30.6422 89.195 28.7422L89.1928 28.7444ZM79.2128 24.5756H89.0719C88.951 21.1022 87.1551 19.6533 84.3248 19.6533C81.4945 19.6533 79.5487 21.0133 79.2128 24.5756Z"  />
        <path d="M103.1 20.4689H102.462C97.9591 20.3178 96.9851 23.58 96.9851 27.8378V33.3933H94.5511V18.2933H96.9851V22.28C97.8987 19.7733 99.6631 18.0822 102.554 18.0822H103.1V20.4689Z"  />
        <path d="M105.353 13.3133H108.488V15.82H105.353V13.3133ZM105.686 18.2956H108.12V33.3956H105.686V18.2956Z"  />
        <path d="M114.421 26.5089C114.482 29.62 116.094 31.8844 120.781 31.8844C124.95 31.8844 126.226 30.4644 126.226 28.14C126.226 26.0244 125.1 24.9689 122.545 24.5467L117.769 23.8222C114.238 23.3089 112.626 21.5867 112.626 18.5667C112.626 15.3356 115.091 12.7689 120.324 12.7689C125.557 12.7689 128.235 15.4267 128.51 19.2911H126.076C125.801 16.5733 124.099 14.7311 120.295 14.7311C116.703 14.7311 115.183 16.2711 115.183 18.5067C115.183 20.0778 115.944 21.3133 118.226 21.6778L123.277 22.4333C126.869 23.0067 128.786 24.5778 128.786 27.8089C128.786 31.6444 126.412 33.8489 120.783 33.8489C114.211 33.8489 111.958 30.4667 111.958 26.5111H114.424L114.421 26.5089Z"  />
        <path d="M145.855 18.2956L138.703 35.9022C137.758 38.3178 137.151 39.4356 134.413 39.4356H131.522V37.4733H135.752L137.427 33.3956H136.574L130.548 18.2956H133.103L137.758 29.9244L138.275 31.3733L138.732 29.9244L143.45 18.2956H145.855Z"  />
        <path d="M162.167 23.76V33.3933H159.733V24.0311C159.733 20.8 158.305 19.8333 156.081 19.8333C152.702 19.8333 150.909 22.4289 150.909 27.6244V33.3933H148.475V18.2933H150.909V22.2511C151.851 19.5044 153.739 17.8111 156.751 17.8111C160.555 17.8111 162.167 19.8644 162.167 23.76Z"  />
        <path d="M172.727 17.8133C177.293 17.8133 179.575 20.32 179.819 23.8222H177.537C177.172 21.3467 175.649 19.7756 172.819 19.7756C169.595 19.7756 167.586 21.4978 167.586 25.8444C167.586 30.1911 169.534 31.9156 172.727 31.9156C175.92 31.9156 177.382 30.1933 177.687 27.6267H180C179.819 31.1 177.351 33.88 172.727 33.88C167.736 33.88 165.118 30.4667 165.118 25.8467C165.118 21.2267 167.797 17.8133 172.727 17.8133Z"  />
        <path d="M37.0515 0C32.6672 0 28.7733 2.08444 26.3214 5.30889L26.3057 5.32889C26.2946 5.34444 26.2834 5.36 26.2699 5.37556L10.9159 25.6022C10.8309 25.7133 10.7794 25.8511 10.7794 26C10.7794 26.3689 11.0794 26.6667 11.4511 26.6667H37.0493C44.4699 26.6667 50.4843 20.6978 50.4843 13.3333C50.4843 5.96889 44.4721 0 37.0515 0ZM37.0515 24H15.495L28.4262 6.96667C28.4352 6.95556 28.4442 6.94222 28.4531 6.93111L28.4665 6.91556C30.4281 4.33556 33.5427 2.66889 37.0493 2.66889C42.9853 2.66889 47.7973 7.44444 47.7973 13.3356C47.7973 19.2267 42.9853 24.0022 37.0493 24.0022L37.0515 24Z"  />
        <path d="M27.591 30C27.591 29.6311 27.291 29.3333 26.9193 29.3333H9.40451C4.20964 29.3333 0 33.5111 0 38.6667C0 43.8222 4.20964 48 9.40451 48C12.4744 48 15.1995 46.54 16.9147 44.2822L16.9259 44.2667C16.9348 44.2556 16.9415 44.2467 16.9505 44.2356L27.4567 30.4C27.5418 30.2867 27.5933 30.1489 27.5933 29.9978L27.591 30ZM14.7942 42.6467C14.7942 42.6467 14.783 42.6622 14.7763 42.6689L14.7673 42.68C13.5402 44.2911 11.5944 45.3356 9.40227 45.3356C5.69197 45.3356 2.68476 42.3511 2.68476 38.6689C2.68476 34.9867 5.69197 32.0022 9.40227 32.0022H22.8753L14.7919 42.6489L14.7942 42.6467Z"  />
      </g>
    </svg>
  );
};

export {
  AppStore,
  AppLinkQR,
  Apple,
  Google,
  Facebook,
  ArrowRight,
  ArrowLeft,
  Checked,
  Delete,
  Visibility,
  EmailSent,
  GooglePlay,
  Logo,
};
