import { Container } from "@partials";
import { AppLinkQR, AppStore, GooglePlay, Logo } from "@icons";
import phone01 from "@images/hero-mobile-01.png";
import phone02 from "@images/hero-mobile-02.png";

const Hero = () => {
  return (
    <div className="hero">
      <Container>
        <div className="hero__content">
          <div className="hero__content-left">
            <div className="hero__content-left__logo">
              <Logo color="white" />
            </div>
            <div className="hero__content-left__copy">
              <h1>
                Unlock
                <br /> yourself
              </h1>
              <h2>Get in sync with your body by tracking your cycle.</h2>
            </div>
            <div className="hero__content-left__links">
              <div className="hero__content-left__links-qr">
                <AppLinkQR />
              </div>
              <div className="hero__content-left__links-store">
                <a href={process.env.REACT_APP_APPSTORE_LINK} target="_blank" rel="noreferrer">
                  <AppStore color="white" />
                </a>
                <a href={process.env.REACT_APP_GOOGLEPLAY_LINK} target="_blank" rel="noreferrer">
                  <GooglePlay color="white" />
                </a>
              </div>
            </div>
          </div>

          <div className="hero__content-right">
            <img className="img01" src={phone01} alt="Phone" />
            <img className="img02" src={phone02} alt="Phone" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export { Hero };
