// Base URL
const baseURL = "https://auth.staging.fhc.scapps.io/v1/";

// Routes
const auth = `${baseURL}auth/`;
const users = `${baseURL}users/`;

// Used urls in API calls
export const urls = {
  // User Login (POST)
  login: `${auth}login`,
  loginFacebook: `${auth}login/facebook`,
  loginGoogle: `${auth}login/google`,
  loginApple: `${auth}login/apple`,

  // Forgot password (POST)
  forgotPass: `${auth}forgot-password`,

  // Forgot password (PUT)
  resetPass: `${auth}reset-password`,

  // User account (GET/DELETE)
  me: `${users}me`,
};